@charset "UTF-8";
/*--------------------------------------------------------
　BREAK POINT
--------------------------------------------------------*/
/*--------------------------------------------------------
　FONT
--------------------------------------------------------*/
/*--------------------------------------------------------
　EASING
--------------------------------------------------------*/
/*--------------------------------------------------------
　MIXIN
--------------------------------------------------------*/
/**
 * SPORTS WELL-BEING トップ用CSS
 * Created on:  2023.9.8
 * Modified on: YYYY.MM.DD
 */
.p-fv__inner {
  position: relative;
  height: 945px;
}
.p-fv__logo {
  text-align: center;
  padding: 207px 0 0;
}
.p-fv__logo .jp {
  display: block;
  font-size: 23px;
  font-weight: 700;
  margin-top: 20px;
}
.p-fv__img1 {
  position: absolute;
  top: -132px;
  left: -382px;
  opacity: 0;
  transform: translate(0, 40px);
}
.p-fv__img1.is-done {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.p-fv__img2 {
  position: absolute;
  top: 428px;
  right: -340px;
  opacity: 0;
  transform: translate(0, 40px);
}
.p-fv__img2.is-done {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
}
.p-fv__img3 {
  position: absolute;
  top: 693px;
  left: 0px;
  opacity: 0;
  transform: translate(0, 40px);
}
.p-fv__img3.is-done {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
}
.p-fv__img4 {
  position: absolute;
  top: 891px;
  left: -212px;
  opacity: 0;
  transform: translate(0, 40px);
}
.p-fv__img4.is-done {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
@media (max-width: 759px) {
  .p-fv__inner {
    height: 156vw;
  }
  .p-fv__logo {
    width: 88.5%;
    margin: 0 auto;
    padding-top: 31.3vw;
  }
  .p-fv__logo .jp {
    font-size: 16px;
  }
  .p-fv__img1 {
    width: 47.2vw;
    top: -11.2vw;
    left: -3.6vw;
  }
  .p-fv__img2 {
    width: 49.3vw;
    top: auto;
    bottom: 0;
    right: -1vw;
  }
  .p-fv__img3 {
    width: 28.5vw;
    top: auto;
    bottom: 36vw;
    left: 25vw;
  }
  .p-fv__img4 {
    width: 32.6vw;
    top: auto;
    bottom: 14.26vw;
    left: 4.4vw;
  }
}
.p-mission {
  padding-top: 135px;
  padding-bottom: 135px;
}
.p-mission__ttl {
  text-align: center;
  margin-bottom: 22px;
}
.p-mission__lead {
  font-family: "Noto Serif JP", serif;
  font-size: 32px;
  line-height: 1.5;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 45px;
  position: relative;
}
.p-mission__lead::before, .p-mission__lead::after {
  content: "";
  position: absolute;
  background: #000000;
  width: 0;
  height: 3px;
  transition: width 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-mission__lead::before {
  left: 0;
  top: 0;
}
.p-mission__lead::after {
  right: 0;
  bottom: 0;
  transition-delay: 0.6s;
}
.p-mission__lead span {
  display: block;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-mission__lead span::before, .p-mission__lead span::after {
  content: "";
  position: absolute;
  background: #000000;
  width: 3px;
  height: 0;
  transition: height 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-mission__lead span::before {
  left: 0;
  bottom: 0;
  transition-delay: 0.9s;
}
.p-mission__lead span::after {
  right: 0;
  top: 0;
  transition-delay: 0.3s;
}
.p-mission__lead.is-done::before, .p-mission__lead.is-done::after {
  width: 100%;
}
.p-mission__lead.is-done span {
  opacity: 1;
}
.p-mission__lead.is-done span::before, .p-mission__lead.is-done span::after {
  height: 100%;
}
.p-mission__txt {
  font-family: "Noto Serif JP", serif;
  font-size: 16px;
  line-height: 2.6;
}
.p-mission__img {
  width: 1008px;
  position: relative;
  left: 50%;
  margin-top: 50px;
  margin-left: -504px;
}
.p-mission__img .img2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.p-mission__img .img2 .sq0 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq1 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq2 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq3 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq4 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq5 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq6 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq7 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq8 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq9 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq10 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq11 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq12 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq13 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq14 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq15 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq16 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq17 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq18 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq19 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq20 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq21 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq22 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq23 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq24 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq25 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq26 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq27 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq28 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq29 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq30 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq31 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq32 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq33 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq34 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq35 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq36 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq37 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq38 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq39 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq40 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq41 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq42 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq43 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq44 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq45 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq46 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq47 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq48 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq49 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq50 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq51 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq52 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq53 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq54 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq55 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq56 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq57 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq58 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq59 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq60 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq61 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq62 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq63 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq64 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq65 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq66 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq67 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq68 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq69 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq70 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq71 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq72 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq73 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq74 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq75 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq76 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq77 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq78 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq79 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq80 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq81 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq82 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .sq83 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-mission__img .img2 .box0, .p-mission__img .img2 .box1, .p-mission__img .img2 .box2, .p-mission__img .img2 .box3, .p-mission__img .img2 .box4, .p-mission__img .img2 .box5, .p-mission__img .img2 .box6, .p-mission__img .img2 .box7, .p-mission__img .img2 .box8, .p-mission__img .img2 .box9 {
  opacity: 0;
  transform: translate(0, -40px);
}
.p-mission__img .img2 .txt0, .p-mission__img .img2 .txt1 {
  opacity: 0;
  transform: translate(-40px, 0);
}
.p-mission__img .img2 .txt2, .p-mission__img .img2 .txt3 {
  opacity: 0;
  transform: translate(40px, 0);
}
.p-mission__img .img2 .line0, .p-mission__img .img2 .line1, .p-mission__img .img2 .line2, .p-mission__img .img2 .line3 {
  stroke: #c4c4c4;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  stroke-width: 1;
}
.p-mission__img.is-done .img2 .sq0 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.5s;
}
.p-mission__img.is-done .img2 .sq1 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.5333333333s;
}
.p-mission__img.is-done .img2 .sq2 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.5666666667s;
}
.p-mission__img.is-done .img2 .sq3 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.6s;
}
.p-mission__img.is-done .img2 .sq4 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.6333333333s;
}
.p-mission__img.is-done .img2 .sq5 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.6666666667s;
}
.p-mission__img.is-done .img2 .sq6 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.7s;
}
.p-mission__img.is-done .img2 .sq7 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.7333333333s;
}
.p-mission__img.is-done .img2 .sq8 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.7666666667s;
}
.p-mission__img.is-done .img2 .sq9 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.8s;
}
.p-mission__img.is-done .img2 .sq10 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.8333333333s;
}
.p-mission__img.is-done .img2 .sq11 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.8666666667s;
}
.p-mission__img.is-done .img2 .sq12 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.9s;
}
.p-mission__img.is-done .img2 .sq13 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.9333333333s;
}
.p-mission__img.is-done .img2 .sq14 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.9666666667s;
}
.p-mission__img.is-done .img2 .sq15 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1s;
}
.p-mission__img.is-done .img2 .sq16 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.0333333333s;
}
.p-mission__img.is-done .img2 .sq17 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.0666666667s;
}
.p-mission__img.is-done .img2 .sq18 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.1s;
}
.p-mission__img.is-done .img2 .sq19 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.1333333333s;
}
.p-mission__img.is-done .img2 .sq20 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.1666666667s;
}
.p-mission__img.is-done .img2 .sq21 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.2s;
}
.p-mission__img.is-done .img2 .sq22 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.2333333333s;
}
.p-mission__img.is-done .img2 .sq23 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.2666666667s;
}
.p-mission__img.is-done .img2 .sq24 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.3s;
}
.p-mission__img.is-done .img2 .sq25 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.3333333333s;
}
.p-mission__img.is-done .img2 .sq26 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.3666666667s;
}
.p-mission__img.is-done .img2 .sq27 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.4s;
}
.p-mission__img.is-done .img2 .sq28 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.4333333333s;
}
.p-mission__img.is-done .img2 .sq29 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.4666666667s;
}
.p-mission__img.is-done .img2 .sq30 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.5s;
}
.p-mission__img.is-done .img2 .sq31 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.5333333333s;
}
.p-mission__img.is-done .img2 .sq32 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.5666666667s;
}
.p-mission__img.is-done .img2 .sq33 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.6s;
}
.p-mission__img.is-done .img2 .sq34 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.6333333333s;
}
.p-mission__img.is-done .img2 .sq35 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.6666666667s;
}
.p-mission__img.is-done .img2 .sq36 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.7s;
}
.p-mission__img.is-done .img2 .sq37 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.7333333333s;
}
.p-mission__img.is-done .img2 .sq38 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.7666666667s;
}
.p-mission__img.is-done .img2 .sq39 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.8s;
}
.p-mission__img.is-done .img2 .sq40 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.8333333333s;
}
.p-mission__img.is-done .img2 .sq41 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.8666666667s;
}
.p-mission__img.is-done .img2 .sq42 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.9s;
}
.p-mission__img.is-done .img2 .sq43 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.9333333333s;
}
.p-mission__img.is-done .img2 .sq44 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1.9666666667s;
}
.p-mission__img.is-done .img2 .sq45 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2s;
}
.p-mission__img.is-done .img2 .sq46 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.0333333333s;
}
.p-mission__img.is-done .img2 .sq47 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.0666666667s;
}
.p-mission__img.is-done .img2 .sq48 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.1s;
}
.p-mission__img.is-done .img2 .sq49 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.1333333333s;
}
.p-mission__img.is-done .img2 .sq50 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.1666666667s;
}
.p-mission__img.is-done .img2 .sq51 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.2s;
}
.p-mission__img.is-done .img2 .sq52 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.2333333333s;
}
.p-mission__img.is-done .img2 .sq53 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.2666666667s;
}
.p-mission__img.is-done .img2 .sq54 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.3s;
}
.p-mission__img.is-done .img2 .sq55 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.3333333333s;
}
.p-mission__img.is-done .img2 .sq56 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.3666666667s;
}
.p-mission__img.is-done .img2 .sq57 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.4s;
}
.p-mission__img.is-done .img2 .sq58 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.4333333333s;
}
.p-mission__img.is-done .img2 .sq59 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.4666666667s;
}
.p-mission__img.is-done .img2 .sq60 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.5s;
}
.p-mission__img.is-done .img2 .sq61 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.5333333333s;
}
.p-mission__img.is-done .img2 .sq62 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.5666666667s;
}
.p-mission__img.is-done .img2 .sq63 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.6s;
}
.p-mission__img.is-done .img2 .sq64 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.6333333333s;
}
.p-mission__img.is-done .img2 .sq65 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.6666666667s;
}
.p-mission__img.is-done .img2 .sq66 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.7s;
}
.p-mission__img.is-done .img2 .sq67 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.7333333333s;
}
.p-mission__img.is-done .img2 .sq68 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.7666666667s;
}
.p-mission__img.is-done .img2 .sq69 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.8s;
}
.p-mission__img.is-done .img2 .sq70 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.8333333333s;
}
.p-mission__img.is-done .img2 .sq71 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.8666666667s;
}
.p-mission__img.is-done .img2 .sq72 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.9s;
}
.p-mission__img.is-done .img2 .sq73 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.9333333333s;
}
.p-mission__img.is-done .img2 .sq74 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 2.9666666667s;
}
.p-mission__img.is-done .img2 .sq75 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3s;
}
.p-mission__img.is-done .img2 .sq76 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3.0333333333s;
}
.p-mission__img.is-done .img2 .sq77 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3.0666666667s;
}
.p-mission__img.is-done .img2 .sq78 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3.1s;
}
.p-mission__img.is-done .img2 .sq79 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3.1333333333s;
}
.p-mission__img.is-done .img2 .sq80 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3.1666666667s;
}
.p-mission__img.is-done .img2 .sq81 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3.2s;
}
.p-mission__img.is-done .img2 .sq82 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3.2333333333s;
}
.p-mission__img.is-done .img2 .sq83 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 3.2666666667s;
}
.p-mission__img.is-done .img2 .box0, .p-mission__img.is-done .img2 .box1, .p-mission__img.is-done .img2 .box2, .p-mission__img.is-done .img2 .box3, .p-mission__img.is-done .img2 .box4, .p-mission__img.is-done .img2 .box5, .p-mission__img.is-done .img2 .box6, .p-mission__img.is-done .img2 .box7, .p-mission__img.is-done .img2 .box8, .p-mission__img.is-done .img2 .box9 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-mission__img.is-done .img2 .box9 {
  transition-delay: 1s;
}
.p-mission__img.is-done .img2 .box8 {
  transition-delay: 1.2s;
}
.p-mission__img.is-done .img2 .box7 {
  transition-delay: 1.4s;
}
.p-mission__img.is-done .img2 .box6 {
  transition-delay: 1.6s;
}
.p-mission__img.is-done .img2 .box5 {
  transition-delay: 1.8s;
}
.p-mission__img.is-done .img2 .box4 {
  transition-delay: 2s;
}
.p-mission__img.is-done .img2 .box3 {
  transition-delay: 2.2s;
}
.p-mission__img.is-done .img2 .box2 {
  transition-delay: 2.4s;
}
.p-mission__img.is-done .img2 .box1 {
  transition-delay: 2.6s;
}
.p-mission__img.is-done .img2 .box0 {
  transition-delay: 2.8s;
}
.p-mission__img.is-done .img2 .txt0, .p-mission__img.is-done .img2 .txt1, .p-mission__img.is-done .img2 .txt2, .p-mission__img.is-done .img2 .txt3 {
  opacity: 0.5;
  transform: translate(0, 0);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
.p-mission__img.is-done .img2 .line0, .p-mission__img.is-done .img2 .line1, .p-mission__img.is-done .img2 .line2, .p-mission__img.is-done .img2 .line3 {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 2s cubic-bezier(0.19, 1, 0.22, 1) 3s;
}
@media (max-width: 959px) {
  .p-mission__img {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
}
@media (max-width: 759px) {
  .p-mission {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .p-mission__inner {
    display: flex;
    flex-direction: column;
  }
  .p-mission__ttl {
    width: 235px;
    margin: 0 auto 15px;
  }
  .p-mission__lead {
    font-size: 18px;
    padding: 15px 0;
    margin-bottom: 20px;
  }
  .p-mission__txt {
    font-size: 14px;
    line-height: 2.3;
  }
  .p-mission__txt:nth-of-type(n + 2) {
    order: 1;
  }
  .p-mission__img {
    margin: 30px 0;
  }
  .p-mission__img .img2 .line0, .p-mission__img .img2 .line1, .p-mission__img .img2 .line2, .p-mission__img .img2 .line3 {
    stroke-width: 2;
  }
}
.p-about {
  padding-top: 135px;
  margin-bottom: 45px;
}
.p-about__circle {
  width: 621px;
  height: 621px;
  margin: 0 auto;
  background: url("../img/about_circle.png") no-repeat 0 0/100% auto;
  position: relative;
  z-index: 1;
}
.p-about__ttl {
  padding-top: 60px;
  margin-bottom: 54px;
  text-align: center;
}
.p-about__lead {
  font-family: "Noto Serif JP", serif;
  width: 405px;
  margin: 0 auto;
}
.p-about__lead .ttl {
  font-size: 30px;
  text-align: center;
}
.p-about__lead .txt {
  font-size: 16px;
  line-height: 2.6;
}
.p-about__bg {
  background: url("../img/about_bg.jpg") no-repeat center top/cover;
  margin-top: -306px;
  padding-top: 306px;
  position: relative;
}
.p-about__bg::before {
  content: "";
  width: 1px;
  height: 0%;
  background: #FFFFFF;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: rotate(45deg);
  transform-origin: right top;
}
.p-about__bg::after {
  content: "";
  width: 1px;
  height: 0%;
  background: #FFFFFF;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: rotate(-45deg);
  transform-origin: left top;
}
.p-about__bg.is-done::before {
  height: 200%;
  transition: height 20s cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
}
.p-about__bg.is-done::after {
  height: 200%;
  transition: height 20s cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
}
.p-about__box {
  padding: 72px 0;
}
.p-about__box.box2 {
  background: rgba(0, 0, 0, 0.13);
}
.p-about__box.box3 {
  background: rgba(0, 0, 0, 0.26);
}
.p-about__box .box-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-about__box .name {
  width: 252px;
  text-align: center;
}
.p-about__box .txt {
  width: 522px;
  font-size: 16px;
  line-height: 2.2;
  color: #FFFFFF;
}
@media (max-width: 759px) {
  .p-about {
    padding-top: 50px;
    margin-bottom: 50px;
  }
  .p-about__ttl {
    width: 235px;
    margin: 0 auto 15px;
  }
  .p-about__circle {
    width: 491px;
    height: 491px;
    left: 50%;
    margin-left: -245px;
  }
  .p-about__lead {
    width: 290px;
  }
  .p-about__lead .ttl {
    font-size: 23px;
  }
  .p-about__lead .txt {
    font-size: 14px;
    line-height: 2.3;
  }
  .p-about__bg {
    margin-top: -115px;
    padding-top: 115px;
  }
  .p-about__box {
    padding: 40px 0;
  }
  .p-about__box .box-inner {
    display: block;
  }
  .p-about__box .txt {
    width: auto;
    font-size: 14px;
    line-height: 1.8;
    margin-top: 1em;
  }
  .p-about__box.box1 .name {
    width: 80px;
  }
  .p-about__box.box2 .name {
    width: 98px;
  }
  .p-about__box.box3 .name {
    width: 72px;
  }
}
.p-action {
  padding-top: 135px;
  padding-bottom: 90px;
}
.p-action__ttl {
  text-align: center;
  margin-bottom: 22px;
}
.p-action__lead {
  font-family: "Noto Serif JP", serif;
  font-size: 32px;
  line-height: 1.5;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 45px;
  position: relative;
}
.p-action__lead::before, .p-action__lead::after {
  content: "";
  position: absolute;
  background: #000000;
  width: 0;
  height: 3px;
  transition: width 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-action__lead::before {
  left: 0;
  top: 0;
}
.p-action__lead::after {
  right: 0;
  bottom: 0;
  transition-delay: 0.6s;
}
.p-action__lead span {
  display: block;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-action__lead span::before, .p-action__lead span::after {
  content: "";
  position: absolute;
  background: #000000;
  width: 3px;
  height: 0;
  transition: height 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-action__lead span::before {
  left: 0;
  bottom: 0;
  transition-delay: 0.9s;
}
.p-action__lead span::after {
  right: 0;
  top: 0;
  transition-delay: 0.3s;
}
.p-action__lead.is-done::before, .p-action__lead.is-done::after {
  width: 100%;
}
.p-action__lead.is-done span {
  opacity: 1;
}
.p-action__lead.is-done span::before, .p-action__lead.is-done span::after {
  height: 100%;
}
.p-action__txt {
  font-family: "Noto Serif JP", serif;
  font-size: 16px;
  line-height: 2.6;
}
.p-action__img {
  width: 936px;
  position: relative;
  left: 50%;
  margin-top: 50px;
  margin-left: -468px;
}
.p-action__img .img2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.p-action__img .img2 .st0, .p-action__img .img2 .st1 {
  stroke: #c5c5c5;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke-width: 1;
}
.p-action__img .img2 #dot1 {
  opacity: 0;
  transform: translate(-40px, 40px);
}
.p-action__img .img2 #dot2 {
  opacity: 0;
  transform: rotate(-10deg);
}
.p-action__img .img2 #circle1, .p-action__img .img2 #circle2, .p-action__img .img2 #circle3, .p-action__img .img2 #circle4 {
  opacity: 0;
  transform: scale(0, 0) rotate(-360deg);
}
.p-action__img .img2 #circle1 {
  transform-origin: 50% 22%;
}
.p-action__img .img2 #circle2 {
  transform-origin: 73% 49%;
}
.p-action__img .img2 #circle3 {
  transform-origin: 50% 78%;
}
.p-action__img .img2 #circle4 {
  transform-origin: 27% 49%;
}
.p-action__img .img2 #en1 {
  opacity: 0;
  transform: translate(0, -20px);
}
.p-action__img .img2 #en2 {
  opacity: 0;
  transform: translate(20px, 0);
}
.p-action__img .img2 #en3 {
  opacity: 0;
  transform: translate(0, 20px);
}
.p-action__img .img2 #en4 {
  opacity: 0;
  transform: translate(-20px, 0);
}
.p-action__img .img2 #ttl1, .p-action__img .img2 #ttl2, .p-action__img .img2 #ttl3, .p-action__img .img2 #ttl4, .p-action__img .img2 #ttl5,
.p-action__img .img2 #txt1, .p-action__img .img2 #txt2, .p-action__img .img2 #txt3, .p-action__img .img2 #txt4, .p-action__img .img2 #txt5, .p-action__img .img2 #en5 {
  opacity: 0;
  transform: translate(0, 30px);
}
.p-action__img.is-done .img2 .st0 {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 5s cubic-bezier(0.19, 1, 0.22, 1) 0.7s;
}
.p-action__img.is-done .img2 .st1 {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 5s cubic-bezier(0.19, 1, 0.22, 1) 1s;
}
.p-action__img.is-done .img2 #ttl1, .p-action__img.is-done .img2 #ttl2, .p-action__img.is-done .img2 #ttl3, .p-action__img.is-done .img2 #ttl4, .p-action__img.is-done .img2 #ttl5,
.p-action__img.is-done .img2 #txt1, .p-action__img.is-done .img2 #txt2, .p-action__img.is-done .img2 #txt3, .p-action__img.is-done .img2 #txt4, .p-action__img.is-done .img2 #txt5, .p-action__img.is-done .img2 #en5 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.p-action__img.is-done .img2 #dot1 {
  opacity: 0.35;
  transform: translate(0, 0);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1) 1.5s;
}
.p-action__img.is-done .img2 #dot2 {
  opacity: 0.35;
  transform: rotate(0deg);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1) 1.5s;
}
.p-action__img.is-done .img2 #circle1, .p-action__img.is-done .img2 #circle2, .p-action__img.is-done .img2 #circle3, .p-action__img.is-done .img2 #circle4 {
  opacity: 1;
  transform: scale(1, 1) rotate(0deg);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-action__img.is-done .img2 #circle1 {
  transition-delay: 0.7s;
}
.p-action__img.is-done .img2 #circle2 {
  transition-delay: 0.8s;
}
.p-action__img.is-done .img2 #circle3 {
  transition-delay: 0.9s;
}
.p-action__img.is-done .img2 #circle4 {
  transition-delay: 1s;
}
.p-action__img.is-done .img2 #en1, .p-action__img.is-done .img2 #en2, .p-action__img.is-done .img2 #en3, .p-action__img.is-done .img2 #en4 {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-action__img.is-done .img2 #en1 {
  transition-delay: 1s;
}
.p-action__img.is-done .img2 #en2 {
  transition-delay: 1.1s;
}
.p-action__img.is-done .img2 #en3 {
  transition-delay: 1.2s;
}
.p-action__img.is-done .img2 #en4 {
  transition-delay: 1.3s;
}
@media (max-width: 759px) {
  .p-action {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .p-action__ttl {
    width: 235px;
    margin: 0 auto 15px;
  }
  .p-action__lead {
    font-size: 18px;
    padding: 15px 0;
    margin-bottom: 20px;
  }
  .p-action__txt {
    font-size: 14px;
    line-height: 2.3;
  }
  .p-action__img {
    width: 100vw;
    margin-top: 30px;
    margin-left: -50vw;
  }
  .p-action__img .item1, .p-action__img .item2, .p-action__img .item3, .p-action__img .item4, .p-action__img .item5 {
    width: 100vw;
    height: 50vw;
    position: relative;
  }
  .p-action__img .item1::before, .p-action__img .item2::before, .p-action__img .item3::before, .p-action__img .item4::before, .p-action__img .item5::before {
    content: "";
    width: 1px;
    height: 0;
    background: #c8c8c8;
    position: absolute;
    transition: height 10s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .p-action__img .item1 .imgbox, .p-action__img .item2 .imgbox, .p-action__img .item3 .imgbox, .p-action__img .item4 .imgbox, .p-action__img .item5 .imgbox {
    width: 27.6%;
    position: absolute;
  }
  .p-action__img .item1 .txtbox, .p-action__img .item2 .txtbox, .p-action__img .item3 .txtbox, .p-action__img .item4 .txtbox, .p-action__img .item5 .txtbox {
    position: absolute;
    opacity: 0;
    transform: translate(0, 30px);
  }
  .p-action__img .item1 .txtbox .name, .p-action__img .item2 .txtbox .name, .p-action__img .item3 .txtbox .name, .p-action__img .item4 .txtbox .name, .p-action__img .item5 .txtbox .name {
    display: block;
  }
  .p-action__img .item1 .txtbox .txt, .p-action__img .item2 .txtbox .txt, .p-action__img .item3 .txtbox .txt, .p-action__img .item4 .txtbox .txt, .p-action__img .item5 .txtbox .txt {
    display: block;
    margin-top: 4vw;
  }
  .p-action__img .item1.is-done::before, .p-action__img .item2.is-done::before, .p-action__img .item3.is-done::before, .p-action__img .item4.is-done::before, .p-action__img .item5.is-done::before {
    height: 300%;
  }
  .p-action__img .item1.is-done .txtbox, .p-action__img .item2.is-done .txtbox, .p-action__img .item3.is-done .txtbox, .p-action__img .item4.is-done .txtbox, .p-action__img .item5.is-done .txtbox {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
  .p-action__img .item1::before, .p-action__img .item3::before, .p-action__img .item5::before {
    top: 0;
    right: 0;
    transform: rotate(45deg);
    transform-origin: top right;
  }
  .p-action__img .item2::before, .p-action__img .item4::before {
    top: 0;
    left: 0;
    transform: rotate(-45deg);
    transform-origin: top left;
  }
  .p-action__img .item1 .imgbox {
    top: 12.66vw;
    right: 12.66vw;
  }
  .p-action__img .item1 .imgbox .shape0 {
    opacity: 0;
    transform: translate(20px, -20px);
  }
  .p-action__img .item1 .imgbox .shape1 {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  .p-action__img .item1 .imgbox .shape2 {
    opacity: 0;
    transform: translate(20px, -10px);
  }
  .p-action__img .item1 .txtbox {
    top: 10vw;
    left: 16.53vw;
  }
  .p-action__img .item1 .txtbox .name {
    width: 33.6vw;
  }
  .p-action__img .item1 .txtbox .txt {
    width: 34.8vw;
  }
  .p-action__img .item1.is-done .imgbox .shape0, .p-action__img .item1.is-done .imgbox .shape1, .p-action__img .item1.is-done .imgbox .shape2 {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .p-action__img .item1.is-done .imgbox .shape0 {
    transition-delay: 0.5s;
  }
  .p-action__img .item1.is-done .imgbox .shape1 {
    transition-delay: 0.9s;
  }
  .p-action__img .item1.is-done .imgbox .shape2 {
    transition-delay: 0.7s;
  }
  .p-action__img .item2 {
    margin-top: -10vw;
  }
  .p-action__img .item2 .imgbox {
    top: 16vw;
    left: 16vw;
  }
  .p-action__img .item2 .imgbox .shape0 {
    opacity: 0;
    transform: translate(-20px, 10px);
  }
  .p-action__img .item2 .imgbox .shape1 {
    opacity: 0;
    transform: translate(0, -20px);
  }
  .p-action__img .item2 .imgbox .shape2 {
    opacity: 0;
    transform: translate(10px, 20px);
  }
  .p-action__img .item2 .txtbox {
    top: 16vw;
    left: 47.2vw;
  }
  .p-action__img .item2 .txtbox .name {
    width: 38.86vw;
  }
  .p-action__img .item2 .txtbox .txt {
    width: 36.04vw;
  }
  .p-action__img .item2.is-done .imgbox .shape0, .p-action__img .item2.is-done .imgbox .shape1, .p-action__img .item2.is-done .imgbox .shape2 {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .p-action__img .item2.is-done .imgbox .shape0 {
    transition-delay: 0.5s;
  }
  .p-action__img .item2.is-done .imgbox .shape1 {
    transition-delay: 0.9s;
  }
  .p-action__img .item2.is-done .imgbox .shape2 {
    transition-delay: 0.7s;
  }
  .p-action__img .item3 {
    margin-top: -3.06vw;
  }
  .p-action__img .item3 .imgbox {
    top: 12.66vw;
    right: 12.66vw;
  }
  .p-action__img .item3 .imgbox .shape0 {
    opacity: 0;
    transform: translate(0, 20px);
  }
  .p-action__img .item3 .imgbox .shape1 {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  .p-action__img .item3 .imgbox .shape2 {
    opacity: 0;
    transform: translate(20px, -10px);
  }
  .p-action__img .item3 .txtbox {
    top: 17.73vw;
    left: 16.53vw;
  }
  .p-action__img .item3 .txtbox .name {
    width: 43.06vw;
  }
  .p-action__img .item3 .txtbox .txt {
    width: 25.06vw;
  }
  .p-action__img .item3.is-done .imgbox .shape0, .p-action__img .item3.is-done .imgbox .shape1, .p-action__img .item3.is-done .imgbox .shape2 {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .p-action__img .item3.is-done .imgbox .shape0 {
    transition-delay: 0.5s;
  }
  .p-action__img .item3.is-done .imgbox .shape1 {
    transition-delay: 0.9s;
  }
  .p-action__img .item3.is-done .imgbox .shape2 {
    transition-delay: 0.7s;
  }
  .p-action__img .item4 {
    margin-top: -10vw;
  }
  .p-action__img .item4 .imgbox {
    top: 15.8vw;
    left: 16vw;
  }
  .p-action__img .item4 .imgbox .shape0 {
    opacity: 0;
    transform: translate(-10px, 20px);
  }
  .p-action__img .item4 .imgbox .shape1 {
    opacity: 0;
    transform: translate(20px, 0);
  }
  .p-action__img .item4 .imgbox .shape2 {
    opacity: 0;
    transform: translate(-20px, 10px);
  }
  .p-action__img .item4 .txtbox {
    top: 16vw;
    left: 47.2vw;
  }
  .p-action__img .item4 .txtbox .name {
    width: 36.8vw;
  }
  .p-action__img .item4 .txtbox .txt {
    width: 38.2vw;
  }
  .p-action__img .item4.is-done .imgbox .shape0, .p-action__img .item4.is-done .imgbox .shape1, .p-action__img .item4.is-done .imgbox .shape2 {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .p-action__img .item4.is-done .imgbox .shape0 {
    transition-delay: 0.5s;
  }
  .p-action__img .item4.is-done .imgbox .shape1 {
    transition-delay: 0.9s;
  }
  .p-action__img .item4.is-done .imgbox .shape2 {
    transition-delay: 0.7s;
  }
  .p-action__img .item5 {
    margin-top: -2.66vw;
  }
  .p-action__img .item5 .imgbox {
    top: 12.4vw;
    right: 12.66vw;
  }
  .p-action__img .item5 .imgbox .shape0 {
    opacity: 0;
    transform: translate(20px, 0);
  }
  .p-action__img .item5 .imgbox .shape1 {
    opacity: 0;
    transform: translate(0, 20px);
  }
  .p-action__img .item5 .imgbox .shape2 {
    opacity: 0;
    transform: translate(-10px, -20px);
  }
  .p-action__img .item5 .txtbox {
    top: 13.33vw;
    left: 16.53vw;
  }
  .p-action__img .item5 .txtbox .name {
    width: 18.93vw;
  }
  .p-action__img .item5 .txtbox .txt {
    width: 38.26vw;
  }
  .p-action__img .item5.is-done .imgbox .shape0, .p-action__img .item5.is-done .imgbox .shape1, .p-action__img .item5.is-done .imgbox .shape2 {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .p-action__img .item5.is-done .imgbox .shape0 {
    transition-delay: 0.5s;
  }
  .p-action__img .item5.is-done .imgbox .shape1 {
    transition-delay: 0.9s;
  }
  .p-action__img .item5.is-done .imgbox .shape2 {
    transition-delay: 0.7s;
  }
}
.p-member {
  padding-top: 135px;
  padding-bottom: 135px;
}
.p-member__inner {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.p-member__object1, .p-member__object2 {
  width: 210px;
  height: 1px;
  position: absolute;
}
.p-member__object1::before, .p-member__object2::before {
  content: "";
  width: 100%;
  height: 1px;
  background: #000000;
  position: absolute;
}
.p-member__object1::after, .p-member__object2::after {
  content: "";
  width: 1px;
  height: 1000px;
  background: #000000;
  position: absolute;
}
.p-member__object1 span, .p-member__object2 span {
  display: block;
  width: 5px;
  height: 126px;
  background: #000000;
  position: absolute;
}
.p-member__object1 {
  top: 0;
  left: -40px;
}
.p-member__object1::before, .p-member__object1::after, .p-member__object1 span {
  top: 0;
  left: 0;
}
.p-member__object1::before {
  transform: scale(0, 1);
  transform-origin: left top;
}
.p-member__object1::after {
  transform: scale(1, 0);
  transform-origin: left top;
}
.p-member__object1 span {
  opacity: 0;
  transform: translate(0, -30px);
  transform-origin: left top;
}
.p-member__object1.is-done::before {
  transform: scale(1, 1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-member__object1.is-done::after {
  transform: scale(1, 1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
}
.p-member__object1.is-done span {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.p-member__object2 {
  bottom: 0;
  right: -40px;
}
.p-member__object2::before, .p-member__object2::after, .p-member__object2 span {
  bottom: 0;
  right: 0;
}
.p-member__object2::before {
  transform: scale(0, 1);
  transform-origin: right bottom;
}
.p-member__object2::after {
  transform: scale(1, 0);
  transform-origin: right bottom;
}
.p-member__object2 span {
  opacity: 0;
  transform: translate(0, 30px);
  transform-origin: right bottom;
}
.p-member__object2.is-done::before {
  transform: scale(1, 1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.p-member__object2.is-done::after {
  transform: scale(1, 1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
}
.p-member__object2.is-done span {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.p-member__ttl {
  width: 100%;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  padding: 20px 0;
  margin-top: 72px;
}
.p-member__ttl:first-of-type {
  margin-top: 0;
}
.p-member__list {
  width: 48%;
}
.p-member__list .name {
  margin-top: 27px;
  font-size: 18px;
  font-weight: 700;
}
.p-member__list .small {
  font-size: 12px;
}
.p-member__outline {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.p-member__outline dt {
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000000;
  letter-spacing: 0.4em;
}
.p-member__outline dt:last-of-type {
  letter-spacing: normal;
}
.p-member__outline dd {
  width: calc(100% - 190px);
  height: 100px;
  padding-left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #000000;
}
@media (max-width: 759px) {
  .p-member {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .p-member__inner {
    padding-top: 0;
    padding-bottom: 0;
    display: block;
  }
  .p-member__object1, .p-member__object2 {
    display: none;
  }
  .p-member__ttl {
    font-size: 15px;
    margin-top: 40px;
  }
  .p-member__list {
    width: 100%;
  }
  .p-member__list .name {
    font-size: 15px;
    margin-top: 20px;
  }
  .p-member__list p, .p-member__list .small {
    font-size: 12px;
  }
  .p-member__outline dt {
    width: 72px;
    height: auto;
    padding: 15px 0;
    font-size: 12px;
    align-items: flex-start;
  }
  .p-member__outline dd {
    width: calc(100% - 72px);
    height: auto;
    padding: 15px 0;
    padding-left: 0;
    font-size: 12px;
  }
}
.p-join {
  background: #f4f4f4;
  padding: 162px 0;
}
.p-join__ttl {
  text-align: center;
}
.p-join__lead {
  font-family: "Noto Serif JP", serif;
  font-size: 16px;
  line-height: 2.6;
  margin: 45px 0;
}
.p-join__list {
  width: calc(100% + 100px);
  margin-left: -50px;
}
.p-join__list li {
  border-bottom: 1px solid #000000;
  padding: 36px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-join__list li .box-L {
  width: 55%;
}
.p-join__list li .box-L .txt {
  text-indent: -4em;
  padding-left: 4em;
}
.p-join__list li .box-R {
  width: 40%;
}
.p-join__list li .genre {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1em;
}
.p-join__list li .txt {
  font-size: 14px;
  line-height: 2;
}
.p-join__list li:first-child {
  border-top: 1px solid #000000;
}
.p-join__btn {
  text-align: center;
  margin-top: 60px;
}
.p-join__btn a {
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: #FFFFFF;
  background: #000000;
  padding: 20px 45px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media (hover: hover) {
  .p-join__btn a:hover {
    opacity: 0.7;
  }
}
@media (max-width: 759px) {
  .p-join {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .p-join__ttl {
    width: 235px;
    margin: 0 auto 15px;
  }
  .p-join__lead {
    font-size: 14px;
    line-height: 2.3;
    margin: 25px 0;
  }
  .p-join__list {
    width: 100%;
    margin-left: 0;
  }
  .p-join__list li {
    padding: 20px 0;
    display: block;
  }
  .p-join__list li .box-L, .p-join__list li .box-R {
    width: 100%;
  }
  .p-join__list li .genre {
    font-size: 15px;
  }
  .p-join__list li .txt {
    font-size: 12px;
  }
  .p-join__list li .box-R {
    margin-top: 15px;
  }
  .p-join__btn {
    margin-top: 30px;
  }
  .p-join__btn a {
    font-size: 13px;
    padding: 15px 30px;
  }
}
